<template>
  <div class="register">
    <div class="register-cont">
      <img
        class="register-cont-title"
        src="../assets/login/logo_title.png"
        alt=""
      />
      <div class="register-cont-cont">
        <!-- <div class="rcc-register">注册</div> -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="160px"
          class="rcc--ruleForm"
        >
          <el-form-item label="检察院名称：" prop="jcyName">
            <el-col :span="16">
              <el-tooltip
                v-if="isLoading"
                class="item"
                effect="dark"
                content="正在加载中，请稍后..."
                placement="top"
              >
                <el-cascader style="width: 100%;"
                  disabled
                  v-model="ruleForm.jcyId"
                  :options="options"
                  :show-all-levels="false"
                  :props="{ expandTrigger: 'hover', emitPath: false }"
                  filterable
                  @change="handleChange"
                ></el-cascader>
              </el-tooltip>
              <el-cascader style="width: 100%;"
                v-else
                ref="cascader"
                v-model="ruleForm.jcyId"
                :options="options"
                :show-all-levels="false"
                :props="{ expandTrigger: 'hover', emitPath: false }"
                filterable
                @change="handleChange"
              ></el-cascader>
              <!-- <el-input v-model="ruleForm.jcyName"></el-input> -->
            </el-col>
          </el-form-item>
          <el-form-item
            label="发起通知的手机号："
            prop="mobile"
            class="display:flex"
          >
            <el-col :span="16"
              ><el-input v-model="ruleForm.mobile"></el-input
            ></el-col>
            <el-col
              :span="8"
              style="
                font-size: calc(100vw * 12 / 1920);
                padding-left: calc(100vw * 10 / 1920);
              "
              >多个手机号中间以英文“,”隔开</el-col
            >
          </el-form-item>
          <el-form-item label="短信开始发送时间" required>
            <el-col :span="16">
              <el-form-item prop="sendingStartTime">
                <el-time-select style="width: 100%;"
                  v-model="ruleForm.sendingStartTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                  }"
                  placeholder="请选择开始时间"
                >
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="短信结束发送时间" required>
            <el-col :span="16">
              <el-form-item prop="sendingEndTime">
                <el-time-select style="width: 100%;"
                  v-model="ruleForm.sendingEndTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                  }"
                  placeholder="请选择结束时间"
                >
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="12309账号：" prop="accountNumber">
            <el-col :span="16"
              ><el-input v-model="ruleForm.accountNumber"></el-input
            ></el-col>
          </el-form-item>
          <el-form-item label="12309密码：" prop="password">
            <el-col :span="16"
              ><el-input v-model="ruleForm.password"></el-input
            ></el-col>
          </el-form-item>
          <div class="btn">
            <el-button type="primary" style="width: 20%;height: 50px;" @click="submitForm('ruleForm')"
              >提交</el-button
            >
            <el-button style="width: 20%;height: 50px;" @click="resetForm('ruleForm')">重置</el-button>
            <el-button style="width: 20%;height: 50px;"><router-link class="link-type" :to="'/'"
                >返回登录页</router-link
              ></el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import cross from "../utils/cross";
import imgUrl from "../../public/avatar.png";
import Vue from "vue";
import qs from "qs";
import axios from "axios";

export default {
  name: "Register",
  data() {
    var validBuildTime = (rule, value, callback) => {
      if (value) {
        const regex = /^[0-9,]+$/;
        if (!regex.test(this.ruleForm.mobile)) {
          callback(new Error("警告哦，只能输入数字和英文逗号"));
        }
      } else {
        callback(new Error("请输入手机号"));
      }
      callback();
    };
    var validateStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      }
      const endTime = this.ruleForm.sendingEndTime;
      if (value && endTime) {
        const endsMinutes =
          parseInt(endTime.split(":")[0]) * 60 +
          parseInt(endTime.split(":")[1]);
        const startsMinutes =
          parseInt(value.split(":")[0]) * 60 + parseInt(value.split(":")[1]);
        if (endsMinutes <= startsMinutes) {
          callback(new Error("开始时间必须早于结束时间"));
        } else {
          callback();
        }
      }
    };
    var validateEndTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择结束时间"));
      }
      const startTime = this.ruleForm.sendingStartTime;
      if (value && startTime) {
        // 将时间转换为分钟进行比较（假设格式为 HH:mm）
        const startMinutes =
          parseInt(startTime.split(":")[0]) * 60 +
          parseInt(startTime.split(":")[1]);
        const endMinutes =
          parseInt(value.split(":")[0]) * 60 + parseInt(value.split(":")[1]);
        if (endMinutes <= startMinutes) {
          callback(new Error("结束时间必须晚于开始时间"));
        } else {
          callback();
        }
      }
    };

    return {
      codeUrl: "",
      ruleForm: {
        jcyName: "",
        mobile: "",
        sendingStartTime: "",
        sendingStartTime: "",
        accountNumber: "",
        password: "",
        jcyId: "",
      },
      options: [],
      rules: {
        jcyId: [
          { required: true, message: "请选择检察院名称", trigger: "change" },
        ],
        mobile: [
          {
            required: true,
            validator: validBuildTime,
            trigger: "blur",
          },
        ],
        sendingStartTime: [
          {
            type: "string",
            required: true,
            validator: validateStartTime,
            trigger: "change",
          },
        ],
        sendingEndTime: [
          {
            type: "string",
            required: true,
            validator: validateEndTime,
            trigger: "change",
          },
        ],
        accountNumber: [
          { required: true, message: "请输入12309账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入12309密码", trigger: "blur" },
        ],
      },
      isLoading: false,
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.initTreeFun();
  },
  methods: {
    initTreeFun() {
      this.isLoading = true;
      cross
        .$axios({
          url: "/f/userInterface/treeData?type=1",
        })
        .then((res) => {
          this.isLoading = false;
          this.options = this.transformTree(res.data);
        });
    },
    transformTree(items) {
      return items.map((item) => {
        const transformedItem = {
          ...item, // 复制当前项的所有属性
          value: item.id, // 将id属性重命名为value
          label: item.name, // 将name属性重命名为label
          children: item.children ? this.transformTree(item.children) : [], // 递归处理子节点
        };
        delete transformedItem.id; // 删除原始的id属性
        delete transformedItem.name; // 删除原始的name属性
        delete transformedItem.parentId; // 删除原始的name属性
        if (transformedItem.children.length == 0) {
          delete transformedItem.children;
        }
        return transformedItem;
      });
    },
    handleChange(value) {
      this.ruleForm.jcyName = this.$refs["cascader"].getCheckedNodes()[0].label;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.password=this.ruleForm.password.replace(/#/g, "%23")
          this.ruleForm.sendingStartTime =
            this.ruleForm.sendingStartTime + ":00";
          this.ruleForm.sendingEndTime = this.ruleForm.sendingEndTime + ":00";
          let data = JSON.stringify(this.ruleForm);
          let url = process.env.VUE_APP_BASE_API + "/f/userInterface/register";

          axios
            .post(url, data, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.$confirm("正在审核中，请稍后...", "提示", {
                confirmButtonText: "前往登录界面",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$message({
                    type: "success",
                    message: "正在跳转",
                  });
                  this.$router.push({
                    name: "Home",
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw * 1920 / 1920);
  height: calc(100vh * 1080 / 1080);
  background-image: url("../assets/login/login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .register-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * 1230 / 1920);
    height: calc(100vh * 900 / 1080);
    .register-cont-title {
      width: calc(100vw * 1000 / 1920);
      margin-bottom: calc(100vh * 40 / 1080);
    }
    .register-cont-cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100vw * 1012 / 1920);
      height: calc(100vh * 632 / 1080);
      min-width: 1092px;
      min-height: 595px;
      background-color: rgba(255,255,255,0.5);
      border-radius: calc(100vw * 20 / 1920);
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .rcc-register {
        font-size: 20px;
        margin-bottom: calc(100vh * 30 / 1080);
      }
      .rcc--ruleForm {
        width: calc(100vw * 800 / 1920);
      }
    }
  }
}
</style>
<style>
.el-input--suffix .el-input__inner {
  height: 50px !important;
}
.el-input--prefix .el-input__inner {
  height: 50px !important;
}
.el-input__inner {
  height: 50px !important;
}
</style>